import React from 'react';
import { useIntl } from 'react-intl';
import Layout from '../components/Layout';
import NotFoundTemplate from '../components/NotFound/index';

const NotFound404 = () => {
  const { formatMessage } = useIntl();
  const f = (id: string) => formatMessage({ id });

  const metaData = {
    title: '404 Page Not Found',
    description: '',
    keyword: [],
  };

  const data = {
    image: '/images/not-found-404.svg',
    imageAlt: f('not_found_404.image.alt'),
    background: '#FFC069',
    title: f('not_found_404.title'),
    text: f('not_found_404.text'),
    button: f('not_found_404.button.text'),
    buttonUrl: '/',
  };

  return (
    <Layout meta={metaData}>
      <main>
        <NotFoundTemplate {...data} />
      </main>
    </Layout>
  );
};

export default NotFound404;
